import React from "react";

import { villa } from "../../assets";

import TestimonialCarousel from "../Testimonials";
import Footer from "../../Components/Footer";
import Benefits from "../Benefits";


import CleaningServiceModal from "./CleaningServicesModel";
import FaqServices from "../../Components/FaqServices";
import Description from "../../Components/Description";

const VillaCleaning = () => {

  const faqData = [
    {
      question: 'How long does it take to clean a villa home?',
      answer: 'The time required to clean a villa home depends on its size and specific cleaning requirements. On average, villa cleaning may take several hours to complete thoroughly.',
    },
    {
      question: 'What areas of the villa are covered in the cleaning service?',
      answer: 'Our villa cleaning service includes the cleaning of all living spaces, bedrooms, bathrooms, kitchen, and common areas. We ensure a comprehensive and detailed cleaning process.',
    },
    {
      question: 'Do you provide cleaning services for outdoor areas of the villa?',
      answer: 'Yes, our villa cleaning service extends to outdoor areas such as balconies, patios, and entrances. We aim to provide a complete and well-rounded cleaning experience.',
    },
    {
      question: 'Are your cleaning products safe for the environment?',
      answer: 'Absolutely, we prioritize the use of eco-friendly and non-toxic cleaning products to ensure a safe and environmentally conscious villa cleaning service.',
    },
    {
      question: 'Is it necessary for residents to vacate during the villa cleaning?',
      answer: 'Residents are not required to vacate during the cleaning process. Our professional cleaners work efficiently, and we take measures to minimize disruption to your daily activities.',
    },
    {
      question: 'Can I schedule recurring cleaning services for my villa?',
      answer: 'Yes, we offer recurring cleaning services for villas to maintain cleanliness on a regular basis. You can schedule weekly, bi-weekly, or monthly cleaning visits according to your preferences.',
    },
    {
      question: "Villa Event Cleaning Services?",
      answer: "Yes, we offer specialized event cleaning services for villas, ensuring your home is pristine before and after events, letting you focus on hosting."
    },
    
    {
      question: "Custom Villa Cleaning Requests?",
      answer: "Absolutely! Our customizable cleaning services can accommodate specific requests or preferences tailored to your villa's unique needs."
    }
    ,
    {
      question: 'Do you provide deep cleaning services for villas?',
      answer: 'Yes, we offer deep cleaning services for villas to address tough dirt, grime, and buildup. Our deep cleaning process ensures a thorough and detailed cleaning of all areas within the villa.',
    },
    {
      question: 'What safety measures do you take during villa cleaning?',
      answer: 'We prioritize safety during villa cleaning by employing trained professionals, using safe cleaning practices and products, and adhering to all necessary safety guidelines to protect both our staff and your property.',
    },
  ];
  
  const data = {
    headingFirst: "Villa",
    headingSecond: "Villa",
    price: "₹7999",


    descriptionMeta:"Experience luxury living with our Villa Cleaning Service. Let our experts handle the details while you enjoy a pristine environment. Schedule your service now!",
    
    description: [ 
      "Thorough cleaning of villa floors using advanced mechanized methods",
  "Extensive cleaning of windows, including window panes, window channels, and grills",
  "Balcony cleaning to maintain a neat and organized exterior for your villa",
  "Efficient removal of cobwebs to ensure a clean and tidy environment",
  "Vacuuming of villa furniture, including sofas, chairs, mattresses, carpets, and curtains",
  "Comprehensive cleaning and disinfection of villa washrooms to meet high standards",
  "Dusting and cleaning of electronic gadgets and equipment in the villa",
  "De-greasing of the kitchen area, ensuring a pristine cooking space",
  "External cleaning of cabinets and wardrobes to enhance the aesthetics of your villa",
  "Cleaning of fans, tubes, switchboards, bulbs, and door handles throughout the villa",
  "Exterior cleaning of appliances like fridge, microwave, and chimney to maintain a sparkling appearance",
     
     
     
     
    ],
    // image: villa,
    images: [villa],
    space: "Royal Space",
    size: "villa",
    
  
  }

  const descriptionData = {
    headingDescription_1: "Why Villa Cleaning Service is Essential",
    paraDescription_1: "Maintaining a clean and hygienic environment is paramount for the comfort and well-being of your family in your villa. With its expansive space and various surfaces, a villa requires thorough cleaning to eliminate dust, dirt, and allergens effectively. Neglecting proper cleaning can lead to the accumulation of harmful bacteria, mold, and unpleasant odors, posing health risks to occupants. Professional villa cleaning service plays a crucial role in ensuring that every corner of your villa is immaculately clean and sanitized. With specialized cleaning techniques and high-quality products, our service helps create a safe, healthy, and inviting living space for you and your loved ones.",
    headingDescription_2: "What We Do in Villa Cleaning Service",
    paraDescription_2: [
        "Dusting and wiping of all surfaces, including furniture, countertops, and fixtures.",
        "Vacuuming and mopping of floors, carpets, and rugs to remove dirt and debris.",
        "Cleaning and disinfecting of bathrooms, including toilets, showers, and sinks.",
        "Washing and sanitizing of kitchen appliances, countertops, and cabinets.",
        "Deep cleaning of bedrooms, including changing linens and tidying up.",
        "Cleaning of windows, sills, and frames to enhance natural light and visibility.",
        "Dusting and polishing of mirrors, frames, and decorative items.",
        "Removing cobwebs and dust from ceilings, walls, and light fixtures.",
        "Disinfecting high-touch surfaces, such as doorknobs, switches, and handles.",
        "Emptying and cleaning of trash bins to maintain a fresh and odor-free environment.",
        "Customized add-on services available upon request to address specific cleaning needs or concerns, such as outdoor area cleaning or special event preparation."
    ]
}

  return (
    <div className="">
      <CleaningServiceModal {...data} images={data.images} />
      <FaqServices faqData={faqData} />
      <Description {...descriptionData} />
      <Benefits />
      <TestimonialCarousel />
      <Footer />
    </div>
  );
};

export default VillaCleaning;
