import React from "react";

import {
  commercial,
  commercial_1,
  commercial_2,
  commercialVideo,
} from "../../assets";

import TestimonialCarousel from "../Testimonials";
import Footer from "../../Components/Footer";
import Benefits from "../Benefits";

import CleaningServiceModal from "./CleaningServicesModel";

import FaqServices from "../../Components/FaqServices";
import Description from "../../Components/Description";

const CommercialCleaning = () => {
  const faqData = [
    {
      question: "What types of commercial spaces do you clean?",
      answer:
        "We provide commercial cleaning services for various spaces, including offices, retail stores, warehouses, medical facilities, educational institutions, and other business establishments.",
    },
    {
      question: "Is your commercial cleaning service customizable?",
      answer:
        "Yes, our commercial cleaning service can be tailored to meet the specific needs of your business. We offer flexible cleaning schedules and customized cleaning plans based on the size and requirements of your commercial space.",
    },
    {
      question: "Do you use environmentally friendly cleaning products?",
      answer:
        "Absolutely! We prioritize the use of eco-friendly and non-toxic cleaning products to create a healthier and safer environment for your employees and clients.",
    },
    {
      question: "How do you ensure the security of our commercial premises?",
      answer:
        "Our commercial cleaning professionals are thoroughly vetted, trained, and insured. We take security seriously and ensure that our team members are trustworthy and adhere to strict security protocols while providing cleaning services.",
    },
    {
      question: "Can you accommodate after-hours cleaning to avoid disruption?",
      answer:
        "Yes, we understand the importance of minimizing disruption to your business operations. We can schedule cleaning services during non-business hours, including evenings and weekends, to ensure a seamless and undisturbed cleaning experience.",
    },
    {
      question: "Do you provide cleaning services for specialized facilities?",
      answer:
        "Yes, we offer cleaning services for specialized facilities such as laboratories, clean rooms, manufacturing plants, and data centers. Our team is trained to handle the unique cleaning requirements of these environments.",
    },
    {
      question: "How do you ensure cleanliness in medical facilities?",
      answer:
        "We implement rigorous sanitation protocols and utilize hospital-grade disinfectants to maintain cleanliness and prevent infections in medical facilities. Our cleaning procedures adhere to industry standards and regulatory guidelines.",
    },

    {
      question:
        "Can you accommodate one-time deep cleaning for commercial spaces?",
      answer:
        "Yes, we provide one-time deep cleaning services for commercial spaces that require thorough cleaning and sanitization. Whether it's a post-construction cleanup or a special event cleanup, we can handle it.",
    },
    {
      question: "Do you clean carpets and upholstery for businesses?",
      answer:
        "Yes, we provide professional carpet and upholstery cleaning services tailored specifically for businesses. Our comprehensive solutions ensure that your commercial space remains clean and inviting.",
    },
    {
      question: "How do you handle waste disposal in commercial cleaning?",
      answer:
        "We follow proper waste management practices and ensure responsible disposal of waste generated during cleaning. Our team segregates and disposes of waste according to local regulations and environmental guidelines.",
    },
  ];
  const data = {
    headingFirst: "Commercial",
    headingSecond: "Commercial",
    price: "₹4999",
    descriptionMeta:
      "Maintain a professional atmosphere with our top-notch Commercial Cleaning Service. From offices to retail spaces, we specialize in thorough and efficient cleaning solutions. Elevate your business environment with our trusted service. Contact us today!",
    description: [
      "Thorough mechanized cleaning of commercial floors and surfaces",
      "Comprehensive cleaning of windows, window panes, sills, and grills",
      "Balcony cleaning to maintain a neat and organized exterior",
      "Removal of cobwebs for a clean and tidy workspace",
      "Vacuuming of office furniture, including sofas, chairs, mattresses, and carpets",
      "Complete cleaning and disinfection of office washrooms",
      "Dusting and cleaning of electronic gadgets and equipment",
      "De-greasing of kitchen areas and communal spaces",
      "External cleaning of cabinets, wardrobes, and storage units",
      "Cleaning of fans, light fixtures, switchboards, bulbs, and door handles",
      "Exterior cleaning of appliances like fridge, microwave, and chimney",
    ],
    // image: commercial,
    images: [commercial, commercial_1, commercial_2],
    space: "commercial space",
    size: "compartment",
  };

  const descriptionDataCommercial = {
    headingDescription_1: "Why Professional Commercial Cleaning is Essential",
    paraDescription_1:
      "Maintaining a clean and sanitary workplace is crucial for ensuring the health, safety, and productivity of employees, as well as leaving a positive impression on clients and visitors. Commercial spaces, such as offices, retail stores, and restaurants, are high-traffic areas that accumulate dirt, dust, and germs throughout the day. Neglecting proper cleaning and maintenance can lead to the spread of illness, decreased employee morale, and potential damage to business reputation. Professional commercial cleaning service plays a vital role in creating a clean, hygienic, and welcoming environment for employees, customers, and guests. With specialized cleaning techniques and commercial-grade equipment, our service effectively eliminates dirt, bacteria, and allergens from all surfaces, ensuring a safe and healthy workspace. Investing in professional commercial cleaning service is not just about cleanliness; it's about promoting employee well-being, enhancing business image, and maintaining a professional standard.",
    headingDescription_2: "What We Do in Professional Commercial Cleaning",
    paraDescription_2: [
      "Thorough cleaning and sanitization of all common areas, including lobbies, hallways, and reception areas.",
      "Dusting and wiping of surfaces, furniture, and fixtures to remove dust, dirt, and fingerprints.",
      "Vacuuming and mopping of floors to remove debris and stains, and restore shine and cleanliness.",
      "Disinfection of high-touch surfaces, such as door handles, light switches, and elevator buttons.",
      "Cleaning and sanitization of restrooms, including toilets, sinks, mirrors, and countertops.",
      "Emptying and disposal of trash and recyclables, and replacement of liners as needed.",
      "Cleaning and polishing of glass doors, windows, and partitions to enhance transparency and appearance.",
      "Detailed cleaning of kitchen or breakroom areas, including appliances, countertops, and sinks.",
      "Customized cleaning schedules and services tailored to the specific needs and preferences of each client.",
      "Regular inspections and quality assurance measures to ensure consistent cleanliness and customer satisfaction.",
    ],
  };

  return (
    <div className="">
      <CleaningServiceModal {...data} images={data.images} />
      <div className="flex items-center justify-center h-[450px] bg-slate-100">
        <div className="video-container text-center">
          <h2 className="text-xl font-bold mb-4 ">
            Watch Our Commercial Cleaning Service in Action
          </h2>
          <video
            src={commercialVideo}
            autoPlay
            muted
            loop
            className="w-[500px] h-[300px] rounded shadow-md bg-sky-100"
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <FaqServices faqData={faqData} />
      <Description {...descriptionDataCommercial} />
      <Benefits />
      <TestimonialCarousel />
      <Footer />
    </div>
  );
};

export default CommercialCleaning;
