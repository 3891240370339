import React from 'react'

import { oofice } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import CleaningServiceModal from './CleaningServicesModel';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';



const OfficeCleaning = () => {

  const faqData = [
    {
      question: 'How frequently should I schedule office cleaning services?',
      answer: 'The frequency of office cleaning services depends on the size and usage of your office space. For most offices, a weekly or bi-weekly cleaning schedule is recommended.',
    },
    {
      question: 'Do you offer cleaning services outside regular business hours?',
      answer: 'Yes, we understand the importance of not disrupting your daily operations. We offer flexible cleaning schedules, including evenings and weekends, to accommodate your business hours.',
    },
    {
      question: 'Can you clean specialized areas in the office, such as computer rooms or conference rooms?',
      answer: 'Certainly. Our office cleaning services are customizable, and we can clean specialized areas, including computer rooms, conference rooms, and other unique spaces within your office.',
    },
    {
      question: 'Are your cleaning products safe for office equipment and electronics?',
      answer: 'Yes, we use safe and industry-approved cleaning products that are suitable for cleaning office equipment, electronics, and other sensitive surfaces.',
    },
    {
      question: 'Do you provide green cleaning options for office cleaning?',
      answer: 'Yes, we offer green cleaning options for environmentally conscious businesses. Our green cleaning products are eco-friendly and promote a healthier indoor environment.',
    },
    {
      question: 'Do you provide janitorial services along with office cleaning?',
      answer: 'Yes, in addition to office cleaning, we offer comprehensive janitorial services to maintain the cleanliness and hygiene of your workplace. Our janitorial services cover a wide range of cleaning tasks to ensure a clean and orderly office environment.',
    },
    {
      question: 'Can you accommodate special events or occasions with your office cleaning services?',
      answer: 'Absolutely! We understand that special events or occasions may require additional cleaning services. Our team is flexible and can provide extra cleaning support to ensure your office space is spotless and presentable for any event.',
    },
    {
      question: 'Do you offer carpet cleaning services as part of your office cleaning packages?',
      answer: 'Yes, we offer carpet cleaning services as part of our comprehensive office cleaning packages. Our professional carpet cleaning services help maintain the cleanliness and appearance of your office carpets, enhancing the overall indoor environment.',
    },
    {
      question: 'How do you ensure confidentiality and security during office cleaning?',
      answer: 'We prioritize confidentiality and security during office cleaning. Our cleaning staff undergo thorough background checks and are trained to handle sensitive information with the utmost discretion. We also implement security protocols to safeguard your office premises during cleaning.',
    },
    {
      question: 'Can you provide references or testimonials from current clients?',
      answer: 'Certainly! We have a portfolio of satisfied clients who can provide references or testimonials regarding our office cleaning services. Feel free to contact us for more information or to request references.',
  },
  ];
  
 
  
  const data = {
    headingFirst: "Office",
    headingSecond: "Office",
    price: "₹6999",
    descriptionMeta: "Elevate your workspace with our meticulous Office Cleaning Service. Create a productive environment with our thorough and professional cleaning solutions. Request a quote now!",
    
    description: [ "Thorough cleaning of windows, window panes, sills, and grills",
    "Balcony cleaning to maintain a neat and organized exterior",
    "Removal of cobwebs to ensure a clean and tidy environment",
    "Vacuuming of office furniture, including sofas, chairs, and carpets",
    "Comprehensive cleaning and disinfection of office washrooms",
    "Dusting and cleaning of electronic gadgets and equipment",
    "De-greasing of the caffetaria, if applicable",
    "Cleaning of cabinets  to enhance office aesthetics",
    "Cleaning of fans, light fixtures, switchboards, and door handles",
     
     
     
     
    ],
    // image: oofice,
    images: [oofice],
  
    space: "Working Space",
    size: "office",
    
  
  }

  const officeCleaningDescriptionData = {
    headingDescription_1: "Why Office Cleaning Service is Essential",
    paraDescription_1: "Maintaining a clean and hygienic office environment is crucial for creating a positive impression on clients, promoting employee productivity, and ensuring the health and well-being of everyone in the workplace. Offices are breeding grounds for germs, bacteria, and allergens, which can spread quickly and lead to increased sick days and decreased morale among employees. Neglecting proper cleaning and sanitation can also result in the buildup of dust, dirt, and clutter, affecting indoor air quality and overall workplace safety. Professional office cleaning service plays a vital role in maintaining a clean, organized, and inviting workspace, enhancing employee satisfaction and fostering a conducive working environment. With specialized cleaning techniques and commercial-grade equipment, our service ensures thorough cleaning and disinfection of all office areas, promoting a healthier and more productive work environment for your business.",
    headingDescription_2: "What We Do in Office Cleaning Service",
    paraDescription_2: [
        "General cleaning of office spaces, including workstations, desks, and common areas.",
        "Dusting and wiping of surfaces, furniture, and electronic equipment to remove dust and debris.",
        "Vacuuming and mopping of floors to remove dirt, stains, and spills.",
        "Cleaning and disinfecting of restrooms, including toilets, sinks, and fixtures.",
        "Sanitization of high-touch surfaces, such as door handles, light switches, and elevator buttons.",
        "Emptying and sanitizing of trash bins and recycling receptacles.",
        "Spot cleaning of glass surfaces, windows, and mirrors for a streak-free shine.",
        "Restocking of essential supplies, including toilet paper, hand soap, and paper towels.",
        "Customized cleaning schedules and services tailored to the specific needs and preferences of your office.",
        "Final inspection and quality assurance to ensure cleanliness standards are met and customer satisfaction.",
    ]
};


   
  return (
    <div className=''>
      <CleaningServiceModal {...data} images={data.images} />
      <FaqServices faqData={faqData} />
      <Description {...officeCleaningDescriptionData} />
      <Benefits />
      <TestimonialCarousel />
      <Footer />

    </div>
  )
}

export default OfficeCleaning
