import React, { useState } from "react";
import { FaAngleDown, FaPhone, FaWhatsapp } from "react-icons/fa";

import HomeCleaning from "../../assets/videos/DeepHomeCleaning.mp4";

import { motion } from "framer-motion";

import Benefits from "../Benefits";

import {
  deepcleaning1,
  home_1,
  home_2,
  home_3,
  home_4,
  home_5,
  check,
} from "../../assets";
import FaqServices from "../../Components/FaqServices";
import TestimonialCarousel from "../Testimonials";
import Footer from "../../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Description from "../../Components/Description";
import MetaData from "../../Metadata";
import RequestFormFlat from "../../Components/RequestFormFlat";

const Deephousecleaning = ({
  headingFirst,
  headingSecond,
  price,
  image,
  options,
  size,
  space,
  selectedLocation,
}) => {
  const formService = {
    headingFirst: "Deep Home Cleaning",
  };

  const flatOptions = ["1 BHK", "2 BHK", "3 BHK", "4 BHK+", "Bungalow"];

  const flatRates = {
    "1 BHK": "3999",
    "2 BHK": "4999",
    "3 BHK": "5499",
    "4 BHK+": "On inspection",
    "2000-3000 sq. ft. Villa": "On inspection",
    "4000-6000 sq. ft. Villa": "On inspection",
    "6000-7000 sq. ft. Villa": "On inspection",
    Bungalow: "On inspection",
  };
  const handleOptionChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedFlat(selectedOption);

    setRate(flatRates[selectedOption]);
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");
  const [service, setService] = useState("Deep Home Cleaning");
  const [rate, setRate] = useState("3999");
  const [selectedFlat, setSelectedFlat] = useState(flatOptions[0]);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [formError, setFormError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const navigate = useNavigate();

  let dataSend = {};

  const validateForm = () => {
    let isValid = true;

    if (name.length < 3) {
      setNameError("Please enter atleast 3 character");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!email.includes("@") || email.length < 5) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!/^\d+$/.test(mobile)) {
      setMobileError("Please enter a valid mobile number");
      isValid = false;
    } else if (mobile.length < 10 || mobile.length > 13) {
      setMobileError("Please enter a valid mobile number");
      isValid = false;
    } else {
      setMobileError("");
    }

    if (!isValid) {
      setFormError("Please correct the errors in the form.");
    } else {
      setFormError("");
    }

    setIsFormValid(isValid);
    return isValid;
  };
  const baseUrl = "https://api.kbshomeservice.com";
  const sendEmail = async () => {
    if (validateForm()) {
      navigate("/responsepage");
      dataSend = {
        name: name,
        email: email,
        mobile: mobile,
        location: location,
        message: message,
        service: service,
        selectedFlat: selectedFlat,
      };

      console.log("Email sent successfully!");
    } else {
      // Form validation failed
      console.log("Form validation failed. Please correct the errors.");
    }

    const res = await fetch(`${baseUrl}/email/sendEmail`, {
      method: "POST",
      body: JSON.stringify(dataSend),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      // HANDLING ERRORS
      .then((res) => {
        console.log(res);
        if (res.status > 199 && res.status < 300) {
          alert("Send Successfully !");
        }
      });

    toast.success("Email sent successfully!");
    navigate("/responsepage");
  };
  const selectedCity = localStorage.getItem("city");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const faqData = [
    {
      question: "What is included in a deep house cleaning service?",
      answer:
        "Our deep house cleaning service includes a comprehensive cleaning of all rooms, surfaces, and fixtures. It goes beyond regular cleaning by addressing neglected or hard-to-reach areas, ensuring a thorough and detailed cleaning experience.",
    },
    {
      question: "How often should I schedule a deep house cleaning?",
      answer:
        "The frequency of deep house cleaning depends on factors such as household size, lifestyle, and personal preferences. However, it is generally recommended to schedule a deep cleaning at least once or twice a year to maintain a healthy and sanitized living environment.",
    },
    {
      question:
        "Do I need to provide cleaning supplies for the deep cleaning service?",
      answer:
        "No, our professional cleaners come fully equipped with all the necessary cleaning supplies and equipment. You don’t need to worry about providing anything – we’ve got it covered.",
    },
    {
      question:
        "Can I customize the deep cleaning service based on my specific needs?",
      answer:
        "Yes, our deep house cleaning service is customizable to meet your specific requirements. Whether you have specific areas you want to focus on or certain preferences, we are flexible and will tailor the service accordingly.",
    },
    {
      question: "Are your deep cleaning professionals trained and insured?",
      answer:
        "Absolutely. Our deep cleaning professionals are highly trained, experienced, and fully insured. You can trust our team to deliver exceptional service with attention to detail and care for your home.",
    },
    {
      question:
        "What is the difference between regular cleaning and deep cleaning?",
      answer:
        "Regular cleaning focuses on maintaining cleanliness on a surface level, while deep cleaning involves a more thorough and intensive cleaning process that targets hidden dirt, grime, and bacteria. Deep cleaning is recommended for less frequent cleaning tasks and for addressing accumulated dirt and stains.",
    },
    {
      question: "Do you clean inside appliances during a deep house cleaning?",
      answer:
        "Yes, as part of our deep house cleaning service, we clean inside appliances such as refrigerators, ovens, microwaves, and dishwashers. This ensures that your appliances are not only clean on the outside but also sanitized and free of food residue and bacteria inside.",
    },
    {
      question:
        "Can you provide eco-friendly cleaning options for deep cleaning?",
      answer:
        "Certainly! We offer eco-friendly cleaning options for customers who prefer environmentally safe cleaning products. Our eco-friendly cleaning solutions are effective yet gentle on the environment, ensuring a healthy and sustainable cleaning experience for your home.",
    },
    {
      question: "How long does a deep house cleaning typically take?",
      answer:
        "The duration of a deep house cleaning service varies depending on factors such as the size of your home, the level of cleaning required, and any additional services requested. On average, a deep cleaning session can take anywhere from a few hours to a full day.",
    },
    {
      question:
        "Do you guarantee satisfaction with your deep cleaning service?",
      answer:
        "Absolutely! We take pride in delivering high-quality deep cleaning services and strive for customer satisfaction. If you're not completely satisfied with the results, we'll work with you to address any concerns and ensure your expectations are met.",
    },
  ];

  const data = {
    headingFirst: "Deep House",
    headingSecond: "Deep Home",
    price: "₹3999",

    image: deepcleaning1,
    space: "living space",
    size: "house",

    descriptionMeta:
      "Revive your home with our comprehensive Deep Home Cleaning Service. Say goodbye to dirt and grime, and hello to freshness. Get started today!",
  };
  const description = [
    "Mechanized cleaning of floors",
    "Cleaning of windows, window panes, window channels, and grills",
    "Cleaning of balconies",
    "Removal of cobwebs",
    "Vacuuming of sofa, chairs, mattresses, carpets, curtains",
    "Cleaning and disinfection of washrooms",
    "Dusting of gadgets",
    "De-greasing of kitchen area",
    "Cleaning of cabinets and wardrobes externally",
    "Cleaning of fans, tubes, switchboard, bulbs, door handles",
    "Fridge, microwave, and chimney exterior cleaning",
  ];

  const descriptionData = {
    headingDescription_1: "Why Deep Home Cleaning is Important",
    paraDescription_1:
      "Maintaining a clean home is essential for creating a healthy and comfortable living environment. However, regular cleaning may not always suffice to tackle deep-seated dirt, allergens, and germs that accumulate over time. This is where deep home cleaning becomes crucial. Deep cleaning goes beyond surface-level tidying to reach into nooks, crannies, and hidden areas that are often overlooked during routine cleaning. By addressing these neglected spaces, deep cleaning helps eliminate allergens, bacteria, and other contaminants that can contribute to respiratory issues, allergies, and overall discomfort. Investing in deep home cleaning ensures that your home remains a safe, hygienic, and inviting space for you and your loved ones. Our deep home cleaning service is meticulously designed to comprehensively cleanse every corner of your living space, leaving it pristine and refreshed. Our trained professionals utilize advanced cleaning techniques and high-quality equipment to ensure thoroughness and effectiveness.",
    headingDescription_2: "What We Do in Deep Home Cleaning",
    paraDescription_2: [
      "Dusting and wiping of all surfaces, including furniture, shelves, and fixtures.",
      "Vacuuming and mopping of floors, paying special attention to corners and baseboards.",
      "Cleaning and disinfecting kitchen appliances, countertops, and backsplashes.",
      "Sanitizing bathrooms, including scrubbing tiles, grout, and fixtures.",
      "Removing cobwebs and dust buildup from ceilings, walls, and light fixtures.",
      "Deep cleaning of upholstery, carpets, and rugs to eliminate embedded dirt and odors.",
      "Washing windows, sills, and frames to enhance natural light and clarity.",
      "Cleaning and disinfecting frequently touched surfaces, such as doorknobs and switches.",
      "Degreasing and cleaning kitchen exhaust fans and vents for improved air quality.",
      "Customized add-on services available upon request to meet specific cleaning needs.",
    ],
  };

  return (
    <>
      <MetaData
        title="Deep Home Cleaning - KBS Home Services"
        descriptionMeta="Revive your home with our comprehensive Deep Home Cleaning Service. Say goodbye to dirt and grime, and hello to freshness. Get started today!
                "
      />
      <div>
        <div className="bg-deep w-full lg:h-[88vh] flex flex-col justify-start items-start">
          <div className="flex  flex-col w-full  p-2 mt-[30px] lg:mt-0 pr-8 pl-8">
            {/* <div className="circlePosition hidden   w-[540px] h-[400px] bg-orange-400 rounded-[100%] absolute z-1 top-[20%] lg:top-[58%] left-[10%] translate-x-[-50%] translate-y-[-50%] blur-[150px]"></div> */}
            <div className="w-full flex flex-col items-center md:flex-row  ">
              <div className=" mt-5 xl:h-[510px] justify-center moto w-full flex flex-col text-white font-normal   rounded-3xl relative  pb-5 lg:p-[20px]  ">
                <div className="flex flex-row justify-between items-center w-full z-10">
                  <motion.h1
                    initial={{ y: "2rem", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 2,
                      type: "spring",
                      delay: 0.75,
                    }}
                    className="flex-1 font-poppins font-bold  ss:text-[65px] text-[25px] text-orange-400 ss:leading-[70.8px] leading-[45px] "
                  >
                    Deep Home Cleaning Services{" "}
                    <br className="sm:block hidden" />{" "}
                    <span className="font-poppins text-white font-bold sm:text-[50px] text-[25px]">
                      {" "}
                      in {selectedCity}
                    </span>
                  </motion.h1>
                </div>
                <div className="hidden lg:flex">
                  <motion.p
                    initial={{ y: "2rem", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 2,
                      type: "spring",
                      delay: 1.05,
                    }}
                    className="font-poppins   font-normal sm:text-[18px] text-[16px] ss:leading-[30px] leading-[25px] text-white max-w-[670px] sm:mt-5 mt-3 text-justify"
                  >
                    Experience a transformative home with KBS Home Service your
                    go-to for impeccable deep cleaning. Call us now to revive
                    your living spaces.
                  </motion.p>
                </div>

                <div className="bg-secondary/20 w-[180px] hidden sm:block h-auto mt-6 rounded-[10px] pl-3 backdrop-blur  justify-center items-center">
                  <div className="w-[180px] items-center flex flex-row gap-3">
                    <div className="stars">
                      <ul className="flex flex-row gap-1 mt-2 ">
                        <li className="text-amber-400 text-lg ">
                          <img
                            loading="lazy"
                            width="20"
                            height="20"
                            src="https://img.icons8.com/fluency/20/star--v1.png"
                            alt="star--v1"
                          />
                        </li>
                        <li className="text-amber-400 text-lg">
                          <img
                            loading="lazy"
                            width="20"
                            height="20"
                            src="https://img.icons8.com/fluency/20/star--v1.png"
                            alt="star--v1"
                          />
                        </li>
                        <li className="text-amber-400 text-lg">
                          <img
                            loading="lazy"
                            width="20"
                            height="20"
                            src="https://img.icons8.com/fluency/20/star--v1.png"
                            alt="star--v1"
                          />
                        </li>
                        <li className="text-amber-400 text-lg">
                          <img
                            loading="lazy"
                            width="20"
                            height="20"
                            src="https://img.icons8.com/fluency/20/star--v1.png"
                            alt="star--v1"
                          />
                        </li>
                      </ul>
                    </div>

                    <div className="rating items-center pt-3 font-poppins text-amber-500 font-semibold sm:text-[22px]">
                      4.8
                    </div>
                  </div>
                  <p className="font-semibold text-[14px] font-poppins mb-2 mt-[-2px] text-white">
                    Based on 442 Reviews
                  </p>
                </div>

                <div className="keypoints relative w-[300px] sm:w-[400px] ">
                  <div className="check relative  grid grid-cols-2 gap-3 mt-6">
                    <div className="absolute sm:hidden left-1/2 top-0 h-full w-px bg-white"></div>
                    {/* Horizontal line */}
                    <div className="absolute sm:hidden left-0 top-1/2 w-full h-px bg-white"></div>

                    <div className=" text-[14px] w-full sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                      <img
                        className="w-[15px] sm:w-[20px]"
                        src={check}
                        loading="lazy"
                        alt="check"
                      />
                      Top Quality Service
                    </div>
                    <div className="text-[14px] w-full  sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                      <img
                        className="w-[15px] sm:w-[20px]"
                        src={check}
                        loading="lazy"
                        alt="check"
                      />
                      Skilled Experts
                    </div>
                    <div className="text-[14px] w-full  sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                      <img
                        className="w-[15px] sm:w-[20px]"
                        src={check}
                        loading="lazy"
                        alt="check"
                      />
                      Best Price Match
                    </div>
                    <div className="text-[14px] w-full  sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                      <img
                        className="w-[15px] sm:w-[20px]"
                        src={check}
                        loading="lazy"
                        alt="check"
                      />
                      Payment after Work
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative lg:mt-0 hidden ">
                <video
                  autoPlay
                  loop
                  muted
                  className="w-[1200px] h-full mt-4 object-cover rounded-2xl "
                >
                  <source src={HomeCleaning} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>

        {/*-------------Booking Section---------- */}
        <div className=" bg-[#F5F7F8] lowersection w-full flex flex-col justify-start items-center md:items-start md:flex-row-reverse md:px-5 gap-3 ">
          {/*---------------Request Form------------ */}
          <RequestFormFlat {...formService} />

          <div className=" mt-8 pb-10 mb-8 rounded-3xl w-full bg-white/70 lg:ml-8 px-2 relative  ">
            <div className="flex flex-col md:flex-col w-auto justify-start items-center sm:mt-0 mt-5 pl-5 pt-5 sm:items-start md:items-start">
              <div className="font-semibold font-poppins text-2xl">
                {headingSecond} Deep Home Cleaning Services
              </div>
              <p className="font-poppins text-[16px] justify-center items-center">
                Book our service, let the dust settle – we'll make your space
                sparkle!
              </p>
              {/* flex replaced by hidden in future will be changed */}
              <div className=" hidden flex-col gap-2 justify-start items-start ">
                <div className="font-semibold font-poppins text-2xl">
                  {headingSecond} Deep Home Cleaning Services
                </div>
                <p className="font-poppins text-[16px] justify-center items-center">
                  Book our service, let the dust settle – we'll make your space
                  sparkle!
                </p>

                <div className=" flex flex-col gap-1">
                  <div className="flex flex-row items-center mt-4">
                    <span className="text-gray-500 font-poppins line-through">
                      ₹9999
                    </span>
                    <span className="text-blue-600 font-bold font-poppins ml-2 text-xl">
                      {rate && <p> ₹{rate}</p>}
                    </span>
                  </div>
                  <span className="text-dimWhite  font-poppins text-sm">
                    (40% OFF on first booking)
                  </span>
                </div>

                <div className="mt-2  font-poppins">
                  <div className="relative ">
                    <select
                      className="block appearance-none font-poppins rounded-xl text-[18px] bg-secondary/20 shadow-xl text-gray-700 w-[220px] py-4 px-[18px]  leading-tight focus:outline-none focus:ring"
                      onChange={handleOptionChange}
                      value={selectedFlat}
                    >
                      <option value="" disabled>
                        {options ? "Select Office Size" : "Select Flat Options"}
                      </option>
                      {options
                        ? options.map((size, index) => (
                            <option key={index} value={size}>
                              {size}
                            </option>
                          ))
                        : flatOptions.map((flat, index) => (
                            <option key={index} value={flat}>
                              {flat}
                            </option>
                          ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-4 flex items-center px-1 text-gray-700">
                      <FaAngleDown />
                    </div>
                  </div>
                </div>

                <div className="flex flex-row justify-center items-center gap-5">
                  <div className="whatsapp">
                    <Link to="https://api.whatsapp.com/send?phone=+916363617916&text=Hi,%20I%20would%20like%20to%20discuss%20further%20on%20the%20services%20offered%20by%20KBS%20Home%20Services...%20Kindly%20connect.%20Thanks">
                      <button className="bg-[#65B741] shadow-[#65B741]/60 shadow-lg mt-2 font-poppins flex flex-row gap-2 justify-center items-center cursor-pointer text-white px-2 py-3 text-sm rounded-lg hover:bg-[#FB8B24]  hover:text-white">
                        <FaWhatsapp />
                        Book on whatsapp
                      </button>
                    </Link>
                  </div>

                  <div className="callback">
                    <Link to="tel:6363617916">
                      {" "}
                      <button className="bg-black font-poppins button7  justify-center items-center cursor-pointer text-white text-sm px-4 py-3 rounded-md   flex flex-row gap-2">
                        {" "}
                        <FaPhone />
                        Tap to Dial
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="deepimagehouse py-5 flex flex-wrap justify-center items-center gap-4 lg:gap-6">
                <img
                  loading="lazy"
                  className="w-[90%] sm:w-[45%] md:w-[30%] lg:w-[350px] lg:h-[465px] h-auto rounded-xl shadow-lg"
                  src={deepcleaning1}
                  alt="deephouse"
                />
                <img
                  loading="lazy"
                  className="w-[90%] sm:w-[45%] md:w-[30%] lg:w-[350px] h-auto rounded-xl shadow-lg"
                  src={home_1}
                  alt="deephouse"
                />
                <img
                  loading="lazy"
                  className="w-[90%] sm:w-[45%] md:w-[30%] lg:w-[350px] h-auto rounded-xl shadow-lg"
                  src={home_2}
                  alt="deephouse"
                />
                <img
                  loading="lazy"
                  className="w-[90%] sm:w-[45%] md:w-[30%] lg:w-[350px] h-auto rounded-xl shadow-lg"
                  src={home_3}
                  alt="deephouse"
                />
                <img
                  loading="lazy"
                  className="w-[90%] sm:w-[45%] md:w-[30%] lg:w-[350px] h-auto rounded-xl shadow-lg"
                  src={home_4}
                  alt="deephouse"
                />
                <img
                  loading="lazy"
                  className="w-[90%] sm:w-[45%] md:w-[30%] lg:w-[350px] h-auto rounded-xl shadow-lg"
                  src={home_5}
                  alt="deephouse"
                />
              </div>
            </div>

            {/*------------- Description section-------------- */}
            <div className=" w-auto justify-start items-center sm:mt-0  pl-5 pt-4">
              <div className="text-xl font-poppins font-semibold mb-4 mt-4">
                Our Cleaning Services
              </div>
              <ul className="list-disc pl-5 font-poppins leading-[25px]">
                {description.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>

              <div className="pt-5">
                <h2 className="text-xl font-poppins font-semibold mb-4">
                  Service Time
                </h2>
                <ul className="list-disc pl-5 font-poppins leading-[25px]">
                  <li>Elevate your {space} in a mere 8 to 12 hours</li>
                </ul>
                <p className="pl-4 text-dimWhite">
                  (Depending upon size of {size} )
                </p>
              </div>

              <div className="pt-5">
                <h2 className="text-xl font-poppins font-semibold mb-4">
                  No. of Servicemen
                </h2>
                <ul className="list-disc pl-5 font-poppins leading-[25px]">
                  <li>
                    2-6{" "}
                    <span className="pl-2 text-dimWhite">
                      (Depending upon size of {size})
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <FaqServices faqData={faqData} />
        <Description {...descriptionData} />
        <Benefits />
        <TestimonialCarousel />

        <Footer />
      </div>
    </>
  );
};

export default Deephousecleaning;
