import React, { useState } from "react";
import {
  
  FaAngleDown,
  FaPhone,
  FaWhatsapp,

} from "react-icons/fa";
import { check, } from "../../assets";
import HomeCleaning from "../../assets/videos/DeepHomeCleaning.mp4";

import { motion } from "framer-motion";


import { Link } from "react-router-dom";
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MetaData from "../../Metadata";
import RequestForm from "../../Components/RequestForm";
import { Helmet } from "react-helmet";


const CleaningServiceModal = ({
  headingFirst,
  headingSecond,
  price,
  description,
  images,
  options,
  size,
  space,
  selectedLocation,
  descriptionMeta
 
}) => {

  const formService = {
    headingFirst: headingFirst
  }

   const flatOptions = [
    "1 BHK",
    "2 BHK",
    "3 BHK",
    "4 BHK+",
    "Bungalow",
  ];
  
  const [rate, setRate] = useState("3999");
  const [service, setService] = useState(`${headingFirst} Services`);
  const [selectedFlat, setSelectedFlat] = useState(flatOptions[0]);


 

  const flatRates = {
    "1 BHK": "3999",
    "2 BHK": "4999",
    "3 BHK": "5499",
    "4 BHK+": "On inspection",
    "2000-3000 sq. ft. Villa": "On inspection",
    "4000-6000 sq. ft. Villa": "On inspection",
    "6000-7000 sq. ft. Villa": "On inspection",
    "Bungalow": "On inspection",
  };
  const handleOptionChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedFlat(selectedOption);
    
    setRate(flatRates[selectedOption]);
  };

  const selectedCity = localStorage.getItem('city')

  return (
    <>
 
     <Helmet>
     <meta charset="utf-8" />
       <title>{`${headingFirst} Cleaning  KBS Home Service`}</title>
       <meta name="description" content="Bathroom cleaning service in Banglore || Free to use " />
       <link rel="canonical" href="https://www.kbshomeservice.com/bathroom-cleaning-in-bangalore" />
         </Helmet>
    
    <div>
      <div className="bg-deep w-full lg:h-[88vh] flex flex-col justify-start items-start">
        <div className="flex  flex-col w-full  p-2 mt-[30px] lg:mt-0 pr-8 pl-8">
          {/* <div className="circlePosition hidden   w-[540px] h-[400px] bg-orange-400 rounded-[100%] absolute z-1 top-[20%] lg:top-[58%] left-[10%] translate-x-[-50%] translate-y-[-50%] blur-[150px]"></div> */}
          <div className="w-full flex flex-col items-center md:flex-row  ">
            <div className=" mt-5 xl:h-[510px] justify-center moto w-full flex flex-col text-white font-normal   rounded-3xl relative  pb-5 lg:p-[20px]  ">
              <div className="flex flex-row justify-between items-center w-full z-10">
                <motion.h1
                  initial={{ y: "2rem", opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    duration: 2,
                    type: "spring",
                    delay: 0.75,
                  }}
                  className="flex-1 font-poppins font-bold  ss:text-[65px] text-[25px] text-orange-400 ss:leading-[70.8px] leading-[45px] "
                >
                  {headingFirst} Cleaning Services <br className="sm:block hidden" />{" "}
                
                    
                 
                  <span className="font-poppins text-white font-bold sm:text-[50px] text-[25px]">
                    {" "}in {selectedCity}
                  </span>
                </motion.h1>
              </div>
              <div className="hidden lg:flex">
                <motion.p

                  initial={{ y: "2rem", opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    duration: 2,
                    type: "spring",
                    delay: 1.05,
                  }}
                  className="font-poppins   font-normal sm:text-[18px] text-[16px] ss:leading-[30px] leading-[25px] text-white max-w-[670px] sm:mt-5 mt-3 text-justify"
                >
                  Experience a transformative home with KBS Home Service your
                  go-to for impeccable deep cleaning. Call us now to revive your
                  living spaces.
                </motion.p>
              </div>

               <div className="bg-secondary/20 w-[180px] hidden sm:block h-auto mt-6 rounded-[10px] pl-3 backdrop-blur  justify-center items-center">
                <div className="w-[180px] items-center flex flex-row gap-3">
                  <div className="stars">
                    <ul className="flex flex-row gap-1 mt-2 ">
                      <li className="text-amber-400 text-lg ">
                        <img
                          loading="lazy"
                          width="20"
                          height="20"
                          src="https://img.icons8.com/fluency/20/star--v1.png"
                          alt="star--v1" />
                      </li>
                      <li className="text-amber-400 text-lg">
                        <img
                          loading="lazy"
                          width="20"
                          height="20"
                          src="https://img.icons8.com/fluency/20/star--v1.png"
                          alt="star--v1" />
                      </li>
                      <li className="text-amber-400 text-lg">
                        <img
                          loading="lazy"
                          width="20"
                          height="20"
                          src="https://img.icons8.com/fluency/20/star--v1.png"
                          alt="star--v1" />
                      </li>
                      <li className="text-amber-400 text-lg">
                        <img
                          loading="lazy"
                          width="20"
                          height="20"
                          src="https://img.icons8.com/fluency/20/star--v1.png"
                          alt="star--v1" />
                      </li>
                    </ul>
                  </div>

                  <div className="rating items-center pt-3 font-poppins text-amber-500 font-semibold sm:text-[22px]">
                    4.8
                  </div>
                </div>
                <p className="font-semibold text-[14px] font-poppins mb-2 mt-[-2px] text-white">
                  Based on 442 Reviews
                </p>
              </div>

              <div className="keypoints relative w-[300px] sm:w-[400px] ">

              
                <div className="check relative  grid grid-cols-2 gap-3 mt-6">
                <div className="absolute sm:hidden left-1/2 top-0 h-full w-px bg-white"></div>
            {/* Horizontal line */}
            <div className="absolute sm:hidden left-0 top-1/2 w-full h-px bg-white"></div>

                
                  <div className=" text-[14px] w-full sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                    <img className="w-[15px] sm:w-[20px]" src={check} loading="lazy" alt="check" />
                    Top Quality Service 

                  </div>
                  <div className="text-[14px] w-full  sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                    <img className="w-[15px] sm:w-[20px]" src={check} loading="lazy" alt="check" />
                    Skilled Experts

                  </div>
                  <div className="text-[14px] w-full  sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                    <img className="w-[15px] sm:w-[20px]" src={check} loading="lazy" alt="check" />
                    Best Price Match

                  </div>
                  <div className="text-[14px] w-full  sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                    <img className="w-[15px] sm:w-[20px]" src={check} loading="lazy" alt="check" />
                    Payment after Work
                  </div>
                </div>
              </div>
            </div>
            <div className="relative lg:mt-0 hidden ">
              <video
                autoPlay
                loop
                muted
                className="w-[1200px] h-full mt-4 object-cover rounded-2xl "
              >
                <source src={HomeCleaning} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>

      {/*-------------Booking Section---------- */}
      <div className=" bg-[#F5F7F8] lowersection w-full flex flex-col md:flex-row-reverse justify-center items-center md:px-5 md:items-start gap-4 ">
        {/*---------------Request Form------------ */}
       <RequestForm {...formService} />



        <div className=" mt-8   rounded-3xl w-full bg-white/70 lg:ml-8 px-2 relative  ">
          <div className="flex flex-col md:flex-col w-auto justify-start items-center sm:mt-0 mt-5 pl-5 pt-5 sm:items-start md:items-start">
            <div className="font-semibold font-poppins text-2xl">
              {headingSecond} Cleaning Services
            </div>
            <p className="font-poppins text-[16px] justify-center items-center">
              Book our service, let the dust settle – we'll make your space
              sparkle!
            </p>
            {/* flex replaced by hidden in future will be changed */}
            <div className=" hidden flex-col gap-2 justify-start items-start ">
              <div className="font-semibold font-poppins text-2xl">
                {headingSecond} Cleaning Services
              </div>
              <p className="font-poppins text-[16px] justify-center items-center">
                Book our service, let the dust settle – we'll make your space
                sparkle!
              </p>

              <div className=" flex flex-col gap-1">
                <div className="flex flex-row items-center mt-4">
                  <span className="text-gray-500 font-poppins line-through">
                    ₹9999
                  </span>
                  <span className="text-blue-600 font-bold font-poppins ml-2 text-xl">
                    {rate && <p> ₹{rate}</p>}
                  </span>
                </div>
                <span className="text-dimWhite  font-poppins text-sm">
                  (40% OFF on first booking)
                </span>
              </div>

              <div className="mt-2  font-poppins">
                <div className="relative ">
                  <select
                    className="block appearance-none font-poppins rounded-xl text-[18px] bg-secondary/20 shadow-xl text-gray-700 w-[220px] py-4 px-[18px]  leading-tight focus:outline-none focus:ring"
                    onChange={handleOptionChange}
                    value={selectedFlat}
                  >
                    <option value="" disabled>
                      {options ? "Select Office Size" : "Select Flat Options"}
                    </option>
                    {options
                      ? options.map((size, index) => (
                        <option key={index} value={size}>
                          {size}
                        </option>
                      ))
                      : flatOptions.map((flat, index) => (
                        <option key={index} value={flat}>
                          {flat}
                        </option>
                      ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-4 flex items-center px-1 text-gray-700">
                    <FaAngleDown />
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-center items-center gap-5">
                <div className="whatsapp">
                  <Link to="https://api.whatsapp.com/send?phone=+916363617916&text=Hi,%20I%20would%20like%20to%20discuss%20further%20on%20the%20services%20offered%20by%20KBS%20Home%20Services...%20Kindly%20connect.%20Thanks"><button className="bg-[#65B741] shadow-[#65B741]/60 shadow-lg mt-2 font-poppins flex flex-row gap-2 justify-center items-center cursor-pointer text-white px-2 py-3 text-sm rounded-lg hover:bg-[#FB8B24]  hover:text-white">
                    <FaWhatsapp />
                    Book on whatsapp
                  </button>
                  </Link>
                </div>

                <div className="callback">
                  <Link to="tel:6363617916">     <button className="bg-black font-poppins button7  justify-center items-center cursor-pointer text-white text-sm px-4 py-3 rounded-md   flex flex-row gap-2">
                    {" "}
                    <FaPhone />
                    Tap to Dial
                  </button></Link>
                </div>
              </div>
            </div>
            {/* <div className="deepimagehouse py-5    flex-col justify-center items-center hidden  lg:flex">
              <img
                loading="lazy"
                className="w-[350px] h-[300px]  rounded-xl shadow-lg"
                src={image}
                alt="deephouse" />
            </div> */}
            
            {/* Image Gallery */}
            <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                {images.map((image, index) => (
                  <div key={index} className="border rounded-lg overflow-hidden">
                    <img
                      src={image}
                      alt={`Water Tank Cleaning ${index + 1}`}
                      className="w-full h-full"
                    />
                  </div>
                ))}
          </div>

          {/*------------- Description section-------------- */}
          <div className=" w-auto justify-start items-center sm:mt-0  pl-5 pt-4">
            <div className="text-xl font-poppins font-semibold mb-4 mt-4">
              Our Cleaning Services
            </div>
            <ul className="list-disc pl-5 font-poppins leading-[25px]">
              {description.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>

            <div className="pt-5">
              <h2 className="text-xl font-poppins font-semibold mb-4">
                Service Time
              </h2>
              <ul className="list-disc pl-5 font-poppins leading-[25px]">
                <li>Elevate your {space} in a mere 8 to 12 hours</li>
              </ul>
              <p className="pl-4 text-dimWhite">
                (Depending upon size of {size} )
              </p>
            </div>

            <div className="pt-5">
              <h2 className="text-xl font-poppins font-semibold mb-4">
                No. of Servicemen
              </h2>
              <ul className="list-disc pl-5 font-poppins leading-[25px]">
                <li>
                  2-6{" "}
                  <span className="pl-2 text-dimWhite">
                    (Depending upon size of {size})
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      </div>


    </div></>
  );
};

export default CleaningServiceModal;
