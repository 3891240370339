import React from 'react'

import {  vacanthouse } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import CleaningServiceModal from './CleaningServicesModel';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const VacantCleaning = () => {


  const faqData = [
    {
      question: 'Why is vacant home cleaning necessary?',
      answer: 'Vacant home cleaning is essential to remove accumulated dust, dirt, and potential allergens. It prepares the space for new occupants and ensures a fresh and clean environment.',
    },
    {
      question: 'What areas of a vacant home do you clean?',
      answer: 'Our vacant home cleaning service includes a comprehensive cleaning of all areas, including floors, walls, windows, bathrooms, kitchen appliances, and any other surfaces that may collect dust or grime.',
    },
    {
      question: 'Can you handle cleaning after renovations or repairs in a vacant home?',
      answer: 'Yes, we can provide cleaning services after renovations or repairs in a vacant home. Our team will ensure that the space is thoroughly cleaned and ready for occupancy.',
    },
    {
      question: 'Do I need to provide cleaning supplies for vacant home cleaning?',
      answer: 'No, our professional cleaners come equipped with all the necessary cleaning supplies and equipment to perform a thorough cleaning of a vacant home.',
    },
    {
      question: 'How can I schedule a vacant home cleaning service?',
      answer: 'You can easily schedule a vacant home cleaning service by contacting our customer service team. We will work with you to find a convenient time for the cleaning.',
    },
    {
      question: "Cleaning for recently purchased or sold vacant homes?",
      answer: "Yes, we offer cleaning services for recently purchased or sold vacant homes, ensuring they are pristine for new occupants or ready for listing."
    },
    
    {
      question: 'Do you offer deep cleaning for neglected vacant homes?',
      answer: 'Absolutely! We specialize in deep cleaning services for neglected vacant homes, tackling tough dirt, grime, and buildup to restore the property to a clean and sanitary condition.',
    },
    {
      question: 'Can you provide exterior cleaning services for vacant homes?',
      answer: 'Yes, we offer exterior cleaning services for vacant homes, including pressure washing of siding, decks, driveways, and other outdoor surfaces to enhance curb appeal and cleanliness.',
    },
    {
      question: 'Do you offer move-in or move-out cleaning for vacant homes?',
      answer: 'Yes, we provide move-in and move-out cleaning services for vacant homes to ensure a smooth transition for tenants or homeowners. Our thorough cleaning process prepares the space for new occupants.',
    },
    {
      question: 'What safety measures do you take during vacant home cleaning?',
      answer: 'We prioritize safety during vacant home cleaning by adhering to strict protocols, using appropriate cleaning products, and ensuring that our team follows all safety guidelines to protect both our staff and the property.',
    },
  ]

  const data = {
    headingFirst: "Vacant Home",
    headingSecond: "Vacant Home",
    price: "₹1999",
    descriptionMeta: "Prepare your vacant house for its next occupants with our Vacant House Cleaning Service. Trust our team to leave every corner spotless, ensuring a welcoming space for new residents. Contact us for a thorough clean!",
    
    description: [
      "Thorough mechanized cleaning of all floors and surfaces",
      "Cleaning of windows, including window panes, sills, and grills",
      "Balcony cleaning to maintain a neat and organized exterior",
      "Complete removal of cobwebs for a clean and tidy environment",
      "Vacuuming of all furniture, including sofas, chairs, mattresses, and carpets",
      "Comprehensive cleaning and disinfection of all washrooms",
      "Dusting and cleaning of electronic gadgets and equipment",
     
     
     
    ],
    // image: vacanthouse,
    images: [vacanthouse],
    space: "vacant space",
    size: "house",
    
  
  }
  const descriptionData = {
    headingDescription_1: "Why Vacant House Cleaning Service is Essential",
    paraDescription_1: "Preparing a vacant house for sale or rental requires thorough cleaning to ensure it is presentable and inviting to potential buyers or tenants. Vacant properties are prone to accumulating dust, dirt, and cobwebs over time, which can detract from their appeal and make them appear neglected. Professional vacant house cleaning service plays a crucial role in revitalizing the property and making it move-in ready. With specialized cleaning techniques and attention to detail, our service transforms vacant houses into pristine and welcoming spaces, enhancing their marketability and value.",
    headingDescription_2: "What We Do in Vacant House Cleaning Service",
    paraDescription_2: [
        "Dusting and wiping down all surfaces, including walls, ceilings, baseboards, and fixtures.",
        "Vacuuming and mopping floors to remove dirt, dust, and debris.",
        "Cleaning and disinfecting bathrooms, including toilets, sinks, tubs, and showers.",
        "Sanitizing kitchen surfaces, cabinets, appliances, and countertops.",
        "Removing cobwebs, dust, and debris from all rooms and storage areas.",
        "Cleaning interior windows, sills, and frames to enhance natural light.",
        "Spot-cleaning walls and doors to remove marks and stains.",
        "Deodorizing the property to eliminate any lingering odors.",
        "Cleaning and sanitizing light fixtures, switches, and outlets.",
        "Disposing of any trash or debris left behind in the property.",
        "Customized add-on services available upon request to address specific cleaning needs or concerns."
    ]
}

   
  return (
    <>
    <CleaningServiceModal {...data} images={data.images}/>
    <FaqServices faqData={faqData} />
    <Description {...descriptionData} />
    <Benefits />
    <TestimonialCarousel />
    <Footer />

    </>
  )
}

export default VacantCleaning
