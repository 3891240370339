import React  from "react";

import {  pressurewash } from "../../assets";

import TestimonialCarousel from "../Testimonials";
import Footer from "../../Components/Footer";
import Benefits from "../Benefits";

import CleaningServiceModal from "./CleaningServicesModel";

import FaqServices from "../../Components/FaqServices";
import Description from "../../Components/Description";



const PressureCar = () => {

    const faqData = [
        {
            question: 'How often should I schedule pressure car washing?',
            answer: 'The frequency of pressure car washing depends on factors such as weather conditions, road debris, and personal preference. It is generally recommended to have your car pressure washed every 1-2 months.',
          },
          {
            question: 'Can pressure car washing damage my car paint?',
            answer: "When done correctly, pressure car washing is safe for your car's paint. We use appropriate pressure levels and cleaning agents to ensure effective cleaning without causing damage.",
          },
          {
            question: 'What areas of the car are covered in pressure car washing?',
            answer: 'Pressure car washing covers the entire exterior of the car, including the body, windows, rims, tires, and other external surfaces. It helps remove dirt, grime, and contaminants from all visible areas.',
          },
          {
            question: 'Do you offer pressure car washing for commercial vehicles?',
            answer: 'Yes, we provide pressure car washing services for a variety of vehicles, including personal cars, fleet vehicles, and commercial vehicles. Our services are customizable to suit different needs.',
          },
          {
            question: 'Is pressure car washing environmentally friendly?',
            answer: 'We use eco-friendly cleaning solutions in our pressure car washing service to minimize environmental impact. Our aim is to provide effective cleaning while being environmentally conscious.',
          },
          {
            question: 'Can pressure car washing remove stubborn stains and contaminants?',
            answer: 'Yes, pressure car washing is effective in removing stubborn stains, bird droppings, insects, and other contaminants from the car’s exterior surfaces. Our high-pressure equipment ensures thorough cleaning.',
          },
          {
            question: 'Do you offer interior cleaning along with pressure car washing?',
            answer: 'Yes, we provide interior cleaning services in addition to pressure car washing. Our comprehensive cleaning package includes both exterior and interior cleaning to ensure your car looks and feels fresh inside and out.',
          },
          {
            question: 'Is pressure car washing suitable for all types of vehicles, including luxury cars?',
            answer: 'Absolutely! Our pressure car washing service is suitable for all types of vehicles, including luxury cars, SUVs, trucks, and more. We use gentle cleaning techniques to ensure the safety of your vehicle’s paint and finish.',
          },
          {
            question: 'Can pressure car washing help prevent rust and corrosion?',
            answer: 'Yes, regular pressure car washing helps remove corrosive substances like salt and road grime, which can contribute to rust and corrosion. By keeping your car clean, you can extend its lifespan and maintain its appearance.',
          },
          {
            question: 'Do you offer mobile pressure car washing services?',
            answer: 'Yes, we offer mobile pressure car washing services for added convenience. Our team can come to your location, whether it’s at home, work, or elsewhere, to provide professional car washing without the hassle of visiting a car wash facility.',
          },
    ]
  const data = {
    headingFirst:" Pressure Washing",
    headingSecond: "Pressure Washing",
    price: "₹4999",
  description: [
   "Powerful pressure washing to remove tough dirt, grime, and contaminants from your car's exterior",
    "Thorough cleaning of the car's body, wheels, and undercarriage for a complete wash",
    "Effective removal of mud, road salt, and other stubborn stains using high-pressure water jets",
    "Cleaning and degreasing of the engine bay to enhance performance and maintain cleanliness",
    "Rinsing and cleaning of windows, mirrors, and external fixtures for a crystal-clear finish",
    "Careful attention to detail to ensure no damage to the car's paint or delicate surfaces",
    "Cleaning and rejuvenation of tire sidewalls and rims for a polished look",
    "Application of protective coatings to shield your car's exterior from future contaminants",
    "Environmentally friendly practices with the use of eco-friendly cleaning solutions",
    "Quick and efficient service to give your car a fresh and clean appearance",
    "Professional handling of delicate areas to preserve the integrity of your vehicle",
      ],
    // image: pressurewash,
    images: [pressurewash],

    space: "car space",
    size: "car",
    descriptionMeta: "Revitalize your vehicle with our Pressure Car Washing Service. Say goodbye to stubborn dirt and grime, and hello to a sparkling clean car. Experience the power of pressure washing for a showroom shine—schedule your wash today!",
    
  
  }
  
  const descriptionDataPressureCarCleaning = {
    headingDescription_1: "Why Pressure Car Cleaning is Essential",
    paraDescription_1: "Regular car washing may not always suffice to remove stubborn dirt, grime, and road residues that accumulate on your vehicle's exterior. Pressure car cleaning offers a thorough and effective solution to tackle tough stains, grease, and debris, ensuring that your car remains clean and pristine. With the power of high-pressure water jets, pressure car cleaning can reach into crevices, wheel wells, and other hard-to-reach areas, removing even the most stubborn contaminants. This not only enhances the appearance of your vehicle but also helps protect its paintwork from damage caused by corrosive substances and environmental pollutants. Investing in pressure car cleaning is an essential part of car maintenance, preserving the integrity and value of your vehicle over time.",
    headingDescription_2: "What We Do in Pressure Car Cleaning",
    paraDescription_2: [
        "Pre-rinsing of the vehicle to loosen and remove surface dirt, mud, and debris.",
        "Application of specialized car cleaning detergent to break down stubborn stains and grease.",
        "Use of high-pressure water jets to thoroughly clean the exterior, including wheels, tires, and undercarriage.",
        "Spot treatment of tough stains and residues using targeted pressure cleaning techniques.",
        "Rinsing of the vehicle to remove soap suds and loosened dirt, leaving a clean and shiny finish.",
        "Drying of the vehicle using microfiber towels or air blowers to prevent water spots and streaks.",
        "Application of protective wax or sealant to enhance shine and provide long-lasting protection against environmental damage.",
        "Detailing of exterior trim, mirrors, and windows to ensure a polished and well-maintained appearance.",
        "Final inspection to ensure that the car is thoroughly cleaned and meets customer satisfaction.",
        "Customized add-on services available upon request to address specific cleaning needs or concerns."
    ]
}

 
  return (
    <div className="">
     <CleaningServiceModal {...data} images={data.images}/>
     <FaqServices faqData={faqData} />
     <Description {...descriptionDataPressureCarCleaning} />
     <Benefits />
     <TestimonialCarousel />
     <Footer />
    </div>
  );
};

export default PressureCar;
