import React from "react";

import { carwash } from "../../assets";

import TestimonialCarousel from "../Testimonials";
import Footer from "../../Components/Footer";
import Benefits from "../Benefits";



import CleaningServiceModal from "./CleaningServicesModel";

import FaqServices from "../../Components/FaqServices";
import Description from "../../Components/Description";



const CarPolish = () => {

  const faqData = [
    {
      question: 'What is car polishing, and why is it necessary?',
      answer: 'Car polishing is a process that restores and enhances the shine of a vehicle’s paint. It helps remove swirl marks, scratches, and oxidation, providing a glossy finish.',
    },
    {
      question: 'How often should I polish my car?',
      answer: 'The frequency of car polishing depends on factors like the car’s exposure to the elements and how often it is washed. On average, once or twice a year is recommended.',
    },
    {
      question: 'Can car polishing remove scratches and swirl marks?',
      answer: 'Yes, professional car polishing can effectively reduce or eliminate scratches, swirl marks, and other minor imperfections in the car’s paintwork.',
    },
    {
      question: 'Is car polishing safe for all types of paint finishes?',
      answer: 'Yes, car polishing is safe for most paint finishes. Our experienced technicians use appropriate products and techniques to ensure a safe and effective polishing process.',
    },
    {
      question: 'Do you offer different levels of car polishing services?',
      answer: 'Yes, we offer various levels of car polishing services, ranging from basic polishing to more advanced paint correction services based on the specific needs of your vehicle.',
    },
    {
      question: 'How long does car polishing take?',
      answer: 'The duration of car polishing varies depending on factors such as the size and condition of the vehicle. On average, it can take anywhere from a few hours to a full day.',
    },
    {
      question: 'Will car polishing remove water spots and bird droppings?',
      answer: 'Yes, car polishing can effectively remove water spots, bird droppings, and other surface contaminants, restoring the clarity and shine of the paintwork.',
    },
    {
      question: 'Can car polishing help protect the paint from future damage?',
      answer: 'Yes, certain polishing products contain protective agents that can help create a barrier against environmental pollutants, UV rays, and other harmful elements, extending the life of the paint finish.',
    },
    {
      question: 'What is the difference between car polishing and waxing?',
      answer: "Car polishing focuses on restoring the paint's shine and correcting imperfections, while waxing provides a protective layer to enhance gloss and repel dirt and water. Both processes complement each other for optimal results.",
    },
    {
      question: 'Do you offer mobile car polishing services?',
      answer: 'Yes, we provide mobile car polishing services for your convenience. Our skilled technicians can perform the polishing process at your location, saving you time and effort.',
  },
  ]
  const data = {
    headingFirst: "Deep Car",
    headingSecond: "Car Cleaning and Polishing",
    price: "₹4999",
    descriptionMeta: "Enhance the appearance of your car with our premium Car Polish Service. Achieve a showroom shine and protect your vehicle's paint for a lasting finish. Elevate your car's appeal with our professional polish! ",
  description: [
        "Thorough cleaning of your car's exterior, including the body, windows, and rims",
        "Comprehensive polishing to bring back the shine and luster to your car's paint",
        "Interior cleaning to remove dirt, dust, and stains from seats, carpets, and floor mats",
        "Detailed cleaning of windows, mirrors, and dashboard for a spotless finish",
        "Vacuuming and cleaning of car upholstery, including seats and carpets",
        "Complete cleaning and sanitization of the car's interior, including the steering wheel and door handles",
        "Dusting and cleaning of electronic components, such as the audio system and navigation console",
        "De-greasing and cleaning of the engine bay for optimal performance",
        "External cleaning and polishing of headlights, tail lights, and other exterior fixtures",
        "Cleaning and conditioning of leather surfaces for a fresh and well-maintained look",
        "Exterior cleaning and polishing of accessories like side mirrors and door handles",
      ],
    // image: carwash,
    images: [carwash],
    space: "car space",
    size: "car",
    
  
  }
  const descriptionData = {
    headingDescription_1: "Why Car Polish Service is Essential",
    paraDescription_1: "Maintaining the exterior appearance of your vehicle is essential not only for aesthetic purposes but also for preserving its value and protecting it from environmental damage. Over time, your car's paint can become dull, faded, and covered in scratches and swirl marks due to exposure to sunlight, pollutants, and regular wear and tear. Neglecting proper care can lead to premature aging and deterioration of the paintwork, ultimately diminishing the overall appeal and resale value of your vehicle. Professional car polish service plays a crucial role in rejuvenating and protecting your car's exterior, restoring its glossy finish and enhancing its appearance. With specialized polishing techniques and high-quality products, our service effectively removes surface imperfections, restores luster, and creates a protective barrier against harmful elements. Investing in professional car polish service is not just about aesthetics; it's about preserving the beauty and integrity of your vehicle for years to come.",
    headingDescription_2: "What We Do in  Car Polish Service",
    paraDescription_2: [
        "Thorough washing and drying of the vehicle to remove dirt, debris, and contaminants from the surface.",
        "Inspection of the paintwork to identify imperfections, scratches, and swirl marks.",
        "Preparation of the surface by claying to remove embedded contaminants and ensure a smooth finish.",
        "Application of professional-grade polishing compounds to restore shine and remove surface defects.",
        "Buffing and polishing of the paintwork to achieve a smooth, glossy finish and enhance clarity.",
        "Application of high-quality carnauba wax or sealant to protect the paint and provide long-lasting shine.",
        "Detailing of exterior trim, chrome accents, and wheels to enhance overall appearance.",
        "Final inspection to ensure a flawless finish and customer satisfaction.",
        "Customized add-on services available upon request to address specific detailing needs or concerns."
    ]
}

  
 
  return (
    <div className="">
     <CleaningServiceModal {...data} images={data.images}/>
     <FaqServices faqData={faqData} />
     <Description {...descriptionData} />
     <Benefits />
     <TestimonialCarousel />
     <Footer />
    </div>
  );
};

export default CarPolish;
