import React from 'react'

import {  watertankcleaning, waterTank1, waterTank2, waterTank3 } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import WatertankModal from './WatertankModal';

import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const WaterTankCleaning = () => {
  const data = {
    headingFirst: " Water Tank  ",
    headingSecond: "Water Tank  ",
    price: "₹3999",
    description: [
      "Thorough cleaning and disinfection of water tanks",
      "Removal of sediment, sludge, and algae from the tank",
      "Inspection for cracks, leaks, or any structural issues",
      "Sanitization of all internal surfaces of the water tank",
      "Scrubbing and cleaning of tank lids and access points",
      "Flushing out accumulated debris and contaminants",
      "Disinfection of inlet and outlet pipes",
      "Checking and maintenance of the water tank's filtration system",
      "Ensuring water quality and taste improvement",
      "Compliance with health and safety standards for water storage",
      "Providing a detailed report on the condition of the water tank",
    ],
    // image: watertankcleaning,
    images: [watertankcleaning, waterTank1, waterTank2, waterTank3],
    space: "watertank space",
    size: "watertank",
    descriptionMeta: "Ensure clean and safe water with our professional Water Tank Cleaning Service. We'll remove sediment and contaminants, preserving water quality and health. Maintain hygiene—schedule your water tank cleaning today!",    
    
  
  }
  const waterTankCleaningFAQ = [
    {
      question: 'How often should I schedule a professional water tank cleaning?',
      answer: 'To maintain clean and safe water, it is recommended to schedule a professional water tank cleaning at least once a year.',
    },
    {
      question: 'What are the potential risks of not cleaning my water tank regularly?',
      answer: 'Neglecting regular water tank cleaning can lead to the accumulation of sediment, bacteria, and contaminants, posing health risks and affecting water quality.',
    },
    {
      question: 'Do you use safe and approved disinfectants for water tank cleaning?',
      answer: 'Yes, we use approved disinfectants and cleaning agents that are safe for water tanks, ensuring the elimination of harmful bacteria and pathogens.',
    },
    {
      question: 'How is the cleaning process carried out for water tanks?',
      answer: 'The water tank cleaning process involves draining the tank, removing sediment and debris, scrubbing and disinfecting the interior, and finally, refilling the tank with clean water.',
    },
    {
      question: 'Can you clean both overhead and underground water tanks?',
      answer: 'Yes, our water tank cleaning service covers both overhead and underground water tanks to ensure comprehensive cleaning and disinfection.',
    },
    {
      question: 'What are the benefits of regular water tank cleaning?',
      answer: 'Regular water tank cleaning ensures clean and safe drinking water, prevents bacterial growth, extends the lifespan of the tank, and maintains water quality.',
    },
    {
      question: 'How long does it take to clean a water tank?',
      answer: 'The duration of water tank cleaning depends on factors such as the size of the tank, the level of contamination, and the cleaning method used. On average, it may take a few hours to complete.',
    },
    {
      question: 'Can I drink water immediately after tank cleaning?',
      answer: 'It is advisable to wait for a short period after tank cleaning to allow any residual disinfectants to dissipate. After that, the water should be safe for consumption.',
    },
    {
      question: 'Do you provide maintenance recommendations after water tank cleaning?',
      answer: 'Yes, after completing the cleaning process, our professionals may provide maintenance recommendations to help you keep your water tank clean and well-maintained.',
    },
    {
      question: 'How can I schedule a water tank cleaning service?',
      answer: 'You can schedule a water tank cleaning service by contacting our company through phone, email, or website. Our team will assist you in setting up an appointment at your convenience.',
    },
  ];

  const waterTankCleaningDescriptionData = {
    headingDescription_1: "Why  Water Tank Cleaning Service is Essential",
    paraDescription_1: "Regular cleaning and maintenance of your  water tank are essential for ensuring the quality and safety of your water supply.  water tanks are susceptible to contamination from various sources, including dust, debris, bird droppings, and algae growth. Without proper cleaning, these contaminants can accumulate in the tank, leading to foul odors, discoloration, and microbial contamination. Professional  water tank cleaning service is vital for removing sediment, disinfecting the tank, and preserving water quality. With specialized equipment and expertise, our service effectively eliminates impurities, restores water clarity, and ensures the integrity of your water supply. Investing in professional  water tank cleaning is crucial for safeguarding your health and maintaining clean, safe, and reliable water for your household needs.",
    headingDescription_2: "What We Do in  Water Tank Cleaning Service",
    paraDescription_2: [
        "Draining the water tank to remove stagnant water and sediment accumulation.",
        "Scrubbing and disinfecting the interior walls and floor of the tank to eliminate microbial growth and odor.",
        "Removing debris, sediment, and algae buildup from the tank to improve water quality.",
        "Inspecting the tank for leaks, cracks, or structural issues that may compromise its integrity.",
        "Applying safe and effective disinfectants to sanitize the tank and prevent bacterial contamination.",
        "Flushing and rinsing the tank thoroughly to remove residual contaminants and cleaning agents.",
        "Performing a final inspection to ensure the tank is clean, watertight, and functioning properly.",
        "Providing recommendations for regular maintenance and scheduling future cleaning appointments as needed.",
        "Emergency services available for  water tank cleaning and repairs to address urgent issues and ensure uninterrupted water supply.",
        "Cleaning and disinfecting water distribution pipes connected to the  water tank to prevent contamination.",
        "Removing rust and corrosion from tank fittings and fixtures to maintain water quality.",
        "Assessing and improving ventilation and drainage systems to prevent moisture buildup and mold growth in the tank."
    ]
}

  

    
  return (
    <div className='main'>
       <WatertankModal {...data} images={data.images}/>
       <FaqServices faqData={waterTankCleaningFAQ} />
       <Description {...waterTankCleaningDescriptionData} />
       <Benefits />
       <TestimonialCarousel />
       <Footer />
    </div>
  )
}

export default WaterTankCleaning
